<template>
    <CMedia class="labels-layout-item" :class="{'labels-layout-item--selected': isSelected()}"
            :aside-image-props="{ height: 120, width: 200 }"
            add-aside-classes="aside-picacode-wrapper">

        <template #aside>
            <img :src="getSampleImage(item)" alt="" height="100px">
        </template>

        <h6 class="mt-2">{{ item.name }}</h6>
        <div v-html="item.description"></div>
        <div class="text-right" style="padding: 1em">
            <CButton v-if="isSelected()"
                     class="labels-layout-item__button"
                     color="primary"
                     @click="$emit('selected', item.id)">
                <translate>Selected</translate>
            </CButton>

            <CButton v-else
                     class="labels-layout-item__button"
                     color="primary" variant="outline"
                     @click="$emit('selected', item.id)">
                <translate>Select</translate>
            </CButton>
        </div>
    </CMedia>
</template>

<script>
    export default {
        props: {
            item: Object,
            selectedId: Number
        },

        methods: {
            isSelected() {
                return this.item.id === this.selectedId
            },

            getSampleImage(labelTemplate) {
                const codec = this.$store.getters['event/codec']
                const supportedCodec = labelTemplate.supported_codecs.find(supportedCodec => supportedCodec.codec_id === codec.id)
                return supportedCodec.sample_image
            }
        }
    }
</script>

<style lang="scss">
    .labels-layout-item {
        position: relative;
        margin-bottom: 1em;
        border-radius: 4px;
        border: 1px solid #d8dbe0;
        padding: 1rem;
    }

    .labels-layout-item--selected {
        border: 1px solid #1472f6;
    }

    .labels-layout-item__button {
        position: absolute;
        bottom: 4px;
        right: 4px;
    }

    .aside-picacode-wrapper {
        background-color: #2e3037;
        width: 150px;
        text-align: center;
        border-radius: 4px;
    }
</style>