<template>
  <CCard>
    <CCardHeader :class="{'border-bottom-0': true}">
      <h5 class="my-2" v-translate>New Pica codes release</h5>
      <div class="card-header-actions">
        <CButton color="primary" @click="open()">
          <translate>New Pica Codes</translate>
        </CButton>
      </div>
    </CCardHeader>

    <CCollapse :show="! isCollapsed" :duration="400">
      <CCardBody class="border-top">
        <CAlert color="warning" closeButton v-if="nonFieldError" :show.sync="showAlert"
                @update:show="$emit('alert-closed')">
          {{ nonFieldError }}
        </CAlert>

        <CRow>
          <CCol sm="12" md="4">
            <CInput :label="`${$gettext('Description')} *`"
                    v-model="data.description"
                    required
                    :plaintext="confirmMode"
                    :is-valid="isFieldValid('description')"
                    :invalid-feedback="getFieldErrors('description')"
            />
          </CCol>

          <CCol md="4">
            <CInput :label="`${$gettext('Quantity')} *`"
                    v-model="data.quantity"
                    type="number"
                    min="0"
                    required
                    :plaintext="confirmMode"
                    :is-valid="isFieldValid('quantity')"
                    :invalid-feedback="getFieldErrors('quantity')"
            >
              <template #append>
              </template>
            </CInput>
          </CCol>

          <CCol sm="12" md="4" v-if="useWelcomeCodeForm">
            <label for="">
              <translate>Welcome code</translate>
              <span class="ml-2"
                      v-c-popover="{
                                        header: $gettext('Welcome code'),
                                        content: $gettext('By entering an alphanumeric code, all the participants at the event can use it to view their photos'),
                                        placement: 'bottom-start'
                                    }">(?)
              </span>
            </label>
            <CInput
                :value="data.welcome_code"
                @input="handleInputWelcomeCode"
                maxlength="9"
                :plaintext="confirmMode"
                :is-valid="isFieldValid('welcome_code')"
                :invalid-feedback="getFieldErrors('welcome_code')"
            />
          </CCol>


        </CRow>

        <CRow v-if="!useWelcomeCodeForm">
          <CCol sm="12" lg="8">
            <CRow>
              <CCol sm="3" md="4" lg="4" xl="4">
                <CInput :label="$gettext('Prefix')"
                        v-model="data.race_number_prefix"
                        :plaintext="confirmMode"
                        :is-valid="isFieldValid('race_number_prefix')"
                        :invalid-feedback="getFieldErrors('race_number_prefix')"
                />
              </CCol>

              <CCol sm="6" md="4" lg="4" xl="4">
                <CInput :label="$gettext('Starting number *')"
                        v-model="data.race_number_seq"
                        type="number"
                        min="0"
                        required
                        :plaintext="confirmMode"
                        :is-valid="isFieldValid('race_number_seq')"
                        :invalid-feedback="getFieldErrors('race_number_seq')"
                />
              </CCol>

              <CCol sm="3" md="4" lg="4" xl="4">
                <CInput :label="$gettext('Suffix')"
                        placeholder=""
                        v-model="data.race_number_suffix"
                        required
                        :plaintext="confirmMode"
                        :is-valid="isFieldValid('race_number_suffix')"
                        :invalid-feedback="getFieldErrors('race_number_suffix')"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12">
                <p><strong>
                  <translate>Preview</translate>
                  :</strong> {{ raceNumberPreviewText }}
                </p>
              </CCol>
            </CRow>
          </CCol>

          <CCol lg="4" v-if="showPrintSeq">
            <label for="">
              <translate>Progressive print number *</translate>
              <span class="ml-2" style="cursor: pointer" v-c-popover="{
                                        header: this.$gettext('Progressive print number'),
                                        content: this.$gettext('Progressive value used for printing'),
                                        placement: 'top-end',

                                    }">(?)</span>

            </label>
            <CInput
                placeholder=""
                v-model.number="data.print_seq"
                required
                :plaintext="confirmMode"
                :is-valid="isFieldValid('print_seq')"
                :invalid-feedback="getFieldErrors('print_seq')"
            >
            </CInput>
          </CCol>
        </CRow>

        <CRow>
          <CCol sm="12" class="mt-4 text-right">
            <template v-if="editMode && !confirmMode">
              <CButton color="primary" variant="outline" size="sm" @click="close()">
                <translate>Cancel</translate>
              </CButton>

              <CButton :disabled="! canGenerate" color="primary" size="sm" class="ml-2" @click="confirmMode = true">
                <translate>Create Pica Codes</translate>
              </CButton>
            </template>

            <template v-else-if="confirmMode">
              <strong class="mr-2" v-if="useWelcomeCodeForm" v-translate='{qty: data.quantity}'>
                You are going to create %{ qty } Pica Codes, are you sure?
              </strong>
              <strong class="mr-2" v-else v-translate='{qty: data.quantity, preview: raceNumberPreviewText}'>
                You are going to create %{ qty } Pica Codes <span>%{ preview }</span>, are you sure?
              </strong>

              <CButton color="primary" variant="outline" size="sm" @click="confirmMode = false">
                <translate>Cancel</translate>
              </CButton>

              <CButton color="primary" size="sm" class="ml-1" @click="createPicacodeRelease()">
                <translate>Proceed</translate>
              </CButton>
            </template>
          </CCol>
        </CRow>

        <CRow>
          <CCol>
            <CButton color="link" class="px-0"
                     @click="setUseWelcomeCodeForm(!useWelcomeCodeForm)">
              <translate v-if="useWelcomeCodeForm">Switch to non welcome code mode</translate>
              <translate v-else>Switch to welcome code mode</translate>
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCollapse>
  </CCard>
</template>


<script>
import PicacodeReleaseService from "../services/picacoderelease.service"
import {mapGetters} from "vuex"

function initialState() {
  return {
    isCollapsed: true,
    editMode: false,
    confirmMode: false,
    showAlert: true,
    useWelcomeCodeForm: false,
    data: {
      description: null,
      quantity: null,
      welcome_code: null,
      race_number_prefix: null,
      race_number_seq: null,
      race_number_suffix: null,
      print_seq: null,
      price_list_id: null
    },
    serverErrors: null,
    nonFieldError: null
  }
}

export default {
  name: "PicacodeReleaseCreate",

  props: {
    nextPrintSeq: {
      type: Number,
      default: null
    },

    showPrintSeq: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return initialState()
  },

  mounted() {
    this.setUseWelcomeCodeForm(this.usesFaceRecognition)
    this.setSequences(this.nextPrintSeq)
  },

  watch: {
    nextPrintSeq: function (newVal) {
      this.setSequences(newVal)
    },

    confirmMode: function (newVal) {
      if (newVal === true) {
        this.serverErrors = null
        this.nonFieldError = null
      }
    },
  },

  computed: {
    ...mapGetters({
      usesFaceRecognition: 'event/usesFaceRecognition',
    }),

    getRaceNumberPrefix: function () {
      return this.data.race_number_prefix ? this.data.race_number_prefix : ''
    },

    getRaceNumberSeq: function () {
      return this.data.race_number_seq ? parseInt(this.data.race_number_seq) : ''
    },

    getRaceNumberSuffix: function () {
      return this.data.race_number_suffix ? this.data.race_number_suffix : ''
    },

    previewRaceNumberFrom: function () {
      return `${this.getRaceNumberPrefix}${this.data.race_number_seq}${this.getRaceNumberSuffix}`
    },

    previewRaceNumberTo: function () {
      // const seq = parseInt(this.raceNumberSeq) + parseInt(this.quantity) - 1
      const seq = this.getRaceNumberSeq + parseInt(this.data.quantity) - 1
      return `${this.getRaceNumberPrefix}${seq}${this.getRaceNumberSuffix}`
    },

    raceNumberPreviewText: function () {
      if (this.data.quantity && this.data.race_number_seq) {
        // const tpl = 'from %{from} to %{to}'

        const translated = this.$gettext('from %{from} to %{to}')

        return this.$gettextInterpolate(translated, {
          from: this.previewRaceNumberFrom,
          to: this.previewRaceNumberTo
        })
      }
      return ''
    },

    canGenerate: function () {
      return this.data.description && this.data.quantity && this.data.race_number_seq && this.data.print_seq
    }
  },

  methods: {
    resetState() {
      Object.assign(this.$data, initialState())
      this.setSequences(this.nextPrintSeq)
    },

    setSequences(val) {
      this.data.print_seq = val
      this.data.race_number_seq = val
    },

    open() {
      this.editMode = true
      this.isCollapsed = false
      // this.loadPriceList()
    },

    close() {
      this.resetState()
      this.$emit('close-clicked')
    },

    // loadPriceList() {
    //     console.log('this.loadPriceList()')
    //     const picaServiceId = this.$store.getters['event/picaServiceId']
    //
    //     PriceListService.getPriceLists(picaServiceId).then((response) => {
    //         response.data.results.forEach((priceList) => {
    //             this.priceLists.push({
    //                 value: priceList.id,
    //                 label: priceList.name,
    //             })
    //         })
    //     })
    // },

    createPicacodeRelease() {
      this.showAlert = true
      this.serverErrors = null
      this.nonFieldError = null

      this.$emit('generate-clicked')

      const params = Object.assign({}, this.data, {pica_service: this.$store.getters['event/picaServiceId']})
      PicacodeReleaseService.createPicacodeRelease(params)
          .then((response) => {
            console.log('PicacodeReleaseService.createRelease response', response)
            this.resetState()
            this.$emit('picacode-release-created', response.data)
          })
          .catch(error => {
            console.log('PicacodeReleaseService.createRelease error', error)

            if (error.response.status === 400) {
              console.log('error', error.response)

              const code = error.response.data.code

              if (code) {
                const errorData = error.response.data.error_data

                if (code === "picacodereleases_race_number_overlap") {
                  console.log('picacodereleases_race_number_overlap!', errorData)
                  const picacodeReleaseIds = errorData.map(o => o.picacode_release_id)

                  const messagePlural = picacodeReleaseIds.length === 1 ? this.$gettext('on the release') : this.$gettext('on the releases')

                  this.nonFieldError = `The requested codes are overlapped ${messagePlural}: ${picacodeReleaseIds.join(', ')}`
                  this.$emit('picacodereleases-overlap', errorData)
                } else if (code === "picacodereleases_print_sequence_overlap") {
                  console.log('picacodereleases_print_sequence_overlap!', errorData)
                  const picacodeReleaseIds = errorData.map(o => o.picacode_release_id)
                  const messagePlural = picacodeReleaseIds.length === 1 ? 'on that of the release' : 'on those of the release'

                  this.nonFieldError = `The print sequence you requested is overlapped ${messagePlural}: ${picacodeReleaseIds.join(', ')}`
                  this.$emit('picacodereleases-overlap', errorData)
                }
              } else {
                this.serverErrors = error.response.data
              }

              this.confirmMode = false
            }
          })
    },

    isFieldValid(fieldName) {
      if (this.serverErrors === null)
        return null
      if (this.serverErrors[fieldName])
        return false
    },

    getFieldErrors(fieldName) {
      if (this.serverErrors && this.serverErrors[fieldName])
        return this.serverErrors[fieldName].join(' ')
      return ''
    },

    handleInputWelcomeCode(e) {
      this.data.welcome_code = e.toUpperCase()
      if (this.serverErrors !== null)
        this.serverErrors['welcome_code'] = null
    },

    setUseWelcomeCodeForm(val) {
      this.useWelcomeCodeForm = val;
    }
  }
}
</script>
