<template>
    <CModal :title="$gettext('Generate labels')" size="lg" :show.sync="largeModal">
        <!--<CAlert show color="warning">Devi selezionare un template</CAlert>-->
        <CRow>
            <CCol sm="12">
                <div class="form-row form-group">
                    <CCol>
                        <CAlert color="warning" closeButton v-show="layoutNotSelectedError">
                            <translate>Select a layout</translate>
                        </CAlert>
                        <CRow>
                            <CCol sm="12" v-for="(layout, index) in layouts" :key="index">
                                <LabelsLayoutItem :item="layout"
                                                  :selected-id="layoutIdSelected"
                                                  @selected="setLayoutSelected($event)"
                                />
                            </CCol>
                        </CRow>
                    </CCol>
                </div>

                <div class="form-row form-group">
                    <CCol sm="3"><translate>Format</translate></CCol>
                    <CCol sm="9">
                        <CInputRadio
                                v-for="(option, optionIndex) in formatOptions"
                                :key="option + optionIndex"
                                :label="option"
                                type="radio"
                                :value="option"
                                name="labelsformat"
                                :inline="true"
                                :custom="true"
                                :checked="formatOptionChecked === option"
                                @update:checked="() => formatOptionChecked = option"
                        />
                    </CCol>
                </div>
            </CCol>
        </CRow>
        <template #footer>
            <CButton @click="close()" color="secondary"><translate>Cancel</translate></CButton>
            <CButton @click="proceed()" color="primary"><translate>Generate</translate></CButton>
        </template>
    </CModal>
</template>


<script>
    import LabelsService from '../services/labels.service'
    import LabelsLayoutItem from "./LabelsLayoutItem"

    function initialState() {
        return {
            largeModal: false,

            picacodeReleaseId: null,
            picacodeReleaseIds: [],

            layouts: [],
            layoutIdSelected: null,
            layoutNotSelectedError: false,

            formatOptions: ['PDF', 'JPG'],
            formatOptionChecked: 'PDF'
        }
    }

    export default {
        name: 'PicacodeReleaseLabelsGenerationModal',

        components: {
            LabelsLayoutItem
        },

        props: {
            picaServiceId: Number
        },

        data() {
            return initialState()
        },

        computed: {
            picaServiceCodec() {
                return this.$store.getters['event/codec']
            }
        },

        /*mounted() {
            console.log('PicacodeReleaseLabelsGenerationModal mounted()')
        },*/

        methods: {
            showModal(picacodeReleases) {

                console.log('showModal()', picacodeReleases)

                const codec = this.picaServiceCodec
                console.log('codec', codec)

                this.largeModal = true

                this.picacodeReleaseIds = []
                if (Array.isArray(picacodeReleases)) { // multi-action
                    picacodeReleases.forEach(picacodeRelease => {
                        this.picacodeReleaseIds.push(picacodeRelease.id)
                    })
                } else { // row action
                    this.picacodeReleaseIds.push(picacodeReleases.id)
                }

                LabelsService.getLabelsLayouts({codecId: codec.id})
                    .then((response) => {
                        this.layouts = response.data.results

                        if (this.layouts.length === 1)
                            this.layoutIdSelected = this.layouts[0].id
                    })
            },

            setLayoutSelected(evt) {
                this.layoutIdSelected = evt
            },

            close() {
                Object.assign(this.$data, initialState())
            },

            proceed() {
                this.layoutNotSelectedError = false
                if (this.layoutIdSelected === null) {
                    this.layoutNotSelectedError = true
                    return false
                }
                console.log('proceed', this)
                this.$emit('proceed', {
                    ids: this.picacodeReleaseIds,
                    layoutId: this.layoutIdSelected,
                    format: this.formatOptionChecked
                })
                Object.assign(this.$data, initialState())
                // this.largeModal = false
            }
        }
    }
</script>