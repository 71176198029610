import { getFileAndDownload } from "@/services/clients/client_core"

const UtilityService = {

    exportCsvUsersNonBuyer(picaServiceId, params) {
        return getFileAndDownload({
            url:`/dashboard/v1/utility/export-csv-users-non-buyer/${picaServiceId}/`,
            params,
            fileType: 'text/csv'
        })
    },

    exportCsvUsersBuyer(picaServiceId) {
        return getFileAndDownload({
            url:`/dashboard/v1/utility/export-csv-users-buyer/${picaServiceId}/`,
            fileType: 'text/csv'
        })
    },

    exportCsvAllUsers(picaServiceId) {
        return getFileAndDownload({
            url:`/dashboard/v1/utility/export-csv-all-users/${picaServiceId}/`,
            fileType: 'text/csv'
        })
    },

    exportCsvListCheckins(picaServiceId) {
         return getFileAndDownload({
            url:`/dashboard/v1/utility/export-csv-checkins/${picaServiceId}/`,
            fileType: 'text/csv'
        })
    },

    downloadAllLabels(picaServiceId) {
        return getFileAndDownload({
            url:`/dashboard/v1/picacode-releases/download-labels/`,
            fileType: 'application/octet-stream',
            params: {pica_service: picaServiceId},
            method: 'post'
        },  undefined, 60000)
    },
}

export default UtilityService
