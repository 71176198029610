import client_core from "@/services/clients/client_core";
// import querystring from 'querystring'

const LabelsService = {
    getLabelsLayouts(args) {
        let p = {}
        if (args.codecId)
            p = {'codec_id': args.codecId}
        // const qs = querystring.stringify({'country': ['PL', 'RU']})
        return client_core.get(`/labels/v1/layouts/`, {params: p})
    },
}

export default LabelsService