<template>
  <div>
    <h1 class="main-header">Pica Codes</h1>

    <CRow>
      <CCol lg="12">
        <PicacodeReleaseCreate
            v-if="hasPerm('pica.add_picacoderelease')"
            :next-print-seq="nextPrintSequence"
            :show-print-seq="showPrintSeq"
            @close-clicked="resetHighlightRows"
            @generate-clicked="resetHighlightRows"
            @alert-closed="resetHighlightRows"
            @picacode-release-created="appendPicacodeRelease($event)"
            @picacodereleases-overlap="highlightRows($event)"
        />

        <CCard>
          <CCardHeader v-if="!isLoading && hasItems">
            <h5 class="my-2" v-translate>All releases</h5>
            <div class="card-header-actions">

              <PButton
                  :loading="generatingAllLabels || releasesWithLabelsGenerationInProgressCount > 0"
                  @click="$refs.picacodeReleaseLabelsGenerationModal.showModal(picacodeReleases)"
                  v-if="showGenerateAllLabels"
                  class="btn btn-sm btn-outline-success mr-2"
                  spinnerColor="success">
                <translate>Generate all labels</translate>
                ({{ picacodeReleases.length }})
              </PButton>
              <PButton
                  :loading="generatingAllLabels || releasesWithLabelsGenerationInProgressCount > 0"
                  @click="$refs.picacodeReleaseLabelsGenerationModal.showModal(picacodeReleases)"
                  v-if="showRegenerateAllLabels"
                  class="btn btn-sm btn-outline-success mr-2"
                  spinnerColor="success">
                <translate>Regenerate all labels</translate>
                ({{ picacodeReleases.length }})
              </PButton>

              <PButton
                  :loading="downloadingAllLabels"
                  @click="downloadAllLabels"
                  class="btn btn-sm btn-outline-primary mr-2"
                  spinnerColor="primary"
                  v-if="showDownloadAllLabels">
                <translate>Download labels</translate>
              </PButton>
               <PButton
                  :loading="downloadingAllPicaCodes"
                  @click="downloadAllPicaCodes"
                  class="btn btn-sm btn-outline-primary"
                  spinnerColor="primary"
                  v-if="showDownloadAllLabels">
                <translate>Download codes</translate>
              </PButton>
            </div>
          </CCardHeader>

          <div class="p-4 text-warning" v-if="!isLoading && !hasItems">
            <translate>No Pica Code has been released yet</translate>
          </div>

          <CCardBody class="p-0" v-else>
            <CDataTable
                :loading="isLoading"
                :items="picacodeReleases"
                :fields="fields"
                :striped="true"
                :bordered="true"
                :fixed="false"
                :responsive="false"
                class="custom-table custom-table--clean"
            >
              <template #no-items-view>
                <translate>Loading...</translate>
              </template>

              <template #description="{ item }">
                <td>
                  {{ item.description }}
                </td>
              </template>

              <template #print_seq="{ item }">
                <td>
                  {{ getPrintSequenceRange(item) }}
                </td>
              </template>

              <template #welcome_code="{ item }">
                <td>
                  {{ item.welcome_code }}
                </td>
              </template>

              <template #race_number_range="{ item }">
                <td>
                  {{ item.first_race_number }} - {{ item.last_race_number }}
                </td>
              </template>

              <template #layout="{ item }">
                <td class="text-center">
                  <img v-if="item.label_sample_image"
                       :src="item.label_sample_image"
                       height="60px"
                       style="cursor: pointer"
                       v-c-popover="{header: `${ item.labels_layout.name }`,
                                     content: `<div class='text-center'><img src='${ item.label_sample_image }' style='height: 200px' /></div>`,
                                     placement: 'right-end'}"
                  />
                  <div v-else>
                    -
                  </div>
                </td>
              </template>

              <template #code_generation_status="{ item }">
                <td>
                  <template
                      v-if="getPicacodeReleaseStatus(item) === PICACODES_GENERATION_IN_PROGRESS">
                    <span style="font-weight:5600" v-translate>Pica Codes generation in progress...</span>
                  </template>

                  <template v-if="getPicacodeReleaseStatus(item) === LABELS_GENERATION_IN_PROGRESS">
                    <div style="font-weight: 500" class="mb-2" v-translate>Generating labels...</div>
                    <CProgress :value="taskCurrent(item)" :max="taskTotal(item)" color="success"
                               show-percentage/>
                  </template>

                  <CButton v-if="showButtonActionGenerateLabels(item)"
                           @click="$refs.picacodeReleaseLabelsGenerationModal.showModal(item)"
                           color="success" v-translate>
                    Generate
                  </CButton>
                  <CButton v-if="showButtonActionRegenerateLabels(item)"
                           @click="$refs.picacodeReleaseLabelsGenerationModal.showModal(item)"
                           color="primary" variant="outline" v-translate>
                    Regenerate
                  </CButton>
                </td>
              </template>

              <template #csv="{ item, index }">
                <td>
                  <CLink v-if="showButtonActionExportCsv(item)" @click="exportCsv(item)">
                    <CIcon name="cipDoc" size="custom-size" :height="24"/>
                  </CLink>
                </td>
              </template>

              <template #zip="{ item, index }">
                <td>
                  <CLink v-if="showButtonActionDownloadLabels(item)"
                         :href="item.labels_file">
                    <CIcon name="cipZip" size="custom-size" :height="22"/>
                  </CLink>
                </td>
              </template>
              <template #actions="{ item, index }">
                <td class="text-right">

                  <CButton class="ml-1 btn btn-sm p-0" @click="toggleDetails(item, index)">
                    <CIcon name="cip-more" style="transform: rotate(90deg)"/>
                  </CButton>
                </td>
              </template>

              <template #details="{ item, index }">
                <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration">
                  <CCardBody class="p-0">
                    <table class="table table-borderless">
                      <tr v-if="item.welcome_code" style="width: 200px">
                        <th>
                          <translate>Gallery URL</translate>
                        </th>
                        <td>
                          {{ item.gallery_url }}
                        </td>
                        <td class="text-right">
                          <CLink class="list-group-item-light"
                                 @click="copyURL(item.gallery_url, $gettext('Gallery URL'))">
                            <CIcon name="cipCopy" size="custom-size" :height="24"/>
                          </CLink>

                        </td>
                      </tr>
                      <tr v-if="item.welcome_code">
                        <th>
                          <translate>Direct Link</translate>
                        </th>
                        <td>
                          {{ getDirectLink(item) }}
                        </td>
                        <td class="text-right">
                          <CLink class="list-group-item-light"
                                 @click="copyURL(getDirectLink(item), $gettext('Direct link'))">
                            <CIcon name="cipCopy" size="custom-size" :height="24"/>
                          </CLink>
                        </td>
                      </tr>
                      <tr v-if="item.welcome_code">
                        <th>
                          <translate>QR code</translate>
                        </th>
                        <td>
                          {{ getQRCode(item) }}
                        </td>
                        <td class="text-right">
                          <CLink class="list-group-item-light"
                                 @click="copyURL(getQRCode(item), $gettext('QR code link'))">
                            <CIcon name="cipCopy" size="custom-size" :height="24"/>
                          </CLink>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">
                              <span style="font-size: 13px">
                      <translate
                          :translate-params="{user: item.created_by.email, date: $options.filters.formatDateTime(item.created_at)}"
                          v-if="item.created_by">
                        Created by %{ user } on %{ date }
                      </translate>
                      <translate
                          :translate-params="{date: $options.filters.formatDateTime(item.created_at)}"
                          v-else>
                        Created on %{ date }
                      </translate>
                    </span>
                        </td>
                        <td class="text-right">
                          <CButton color="danger" size="sm" variant="outline"
                                   v-c-tooltip="$gettext('Delete Pica Code release')"
                                   v-if="hasPerm('pica.delete_picacoderelease')"
                                   @click="showDeleteConfirmModal(item, index)">
                            <translate>Delete</translate>
                          </CButton>
                        </td>
                      </tr>
                    </table>


                  </CCardBody>


                </CCollapse>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <PicacodeReleaseLabelsGenerationModal ref="picacodeReleaseLabelsGenerationModal"
                                          @proceed="startLabelsGeneration($event)"/>

    <CModal :title="$gettext('Confirm deletion')" :show.sync="openDeleteConfirmModal">

      <template v-if="canDeletePicacodeRelease(itemToDelete)">
        <CAlert show color="info" class="mb-0">
          <translate>Before proceeding, make sure you haven't already sent your labels to print.</translate>
        </CAlert>
        <p class="mt-3 mb-0" v-translate>Do I proceed with the deletion?</p>
      </template>

      <template v-else>
        <CAlert show color="info" class="mb-0">
          <translate>It is not possible to proceed with the deletion</translate>
          <span v-if="isEventStarted || itemToDelete && itemToDelete.has_checkins">:&nbsp;</span>
          <translate v-if="itemToDelete && itemToDelete.has_checkins">Pica Codes of this release have already
            been used by users.
          </translate>
          <translate v-else-if="isEventStarted">event is already started.</translate>
        </CAlert>
      </template>

      <template #footer>
        <CButton @click="hideDeleteConfirmModal()" color="secondary" size="sm">
          <template v-if="canDeletePicacodeRelease(itemToDelete)">
            <translate>Cancel</translate>
          </template>

          <template v-else>
            <translate>Close</translate>
          </template>
        </CButton>

        <CButton v-if="canDeletePicacodeRelease(itemToDelete)" size="sm"
                 @click="deletePicacodeRelease(itemToDelete, itemToDeleteIndex)" color="primary">
          <translate>Proceed</translate>
        </CButton>
      </template>
    </CModal>
  </div>
</template>


<script>
import PicacodeReleaseService from "@/services/picacoderelease.service"
import PicacodeReleaseLabelsGenerationModal from "@/components/PicacodeReleaseLabelsGenerationModal"
import PicacodeReleaseCreate from "@/components/PicacodeReleaseCreate"
import iconMore from '@/assets/icons/more.svg'
import {mapGetters} from "vuex"
import UtilityService from "@/services/utility.service"
import PButton from "@/components/PButton"

const PICACODES_GENERATION_IN_PROGRESS = 1
const PICACODES_GENERATION_COMPLETED = 2
const LABELS_GENERATION_IN_PROGRESS = 3
const LABELS_GENERATION_COMPLETED = 4

export default {
  name: "PicacodeReleaseList",

  components: {
    PicacodeReleaseCreate,
    PicacodeReleaseLabelsGenerationModal,
    PButton
  },

  data() {
    return {
      PICACODES_GENERATION_IN_PROGRESS,
      PICACODES_GENERATION_COMPLETED,
      LABELS_GENERATION_IN_PROGRESS,
      LABELS_GENERATION_COMPLETED,
      collapseDuration: 0,
      fields: null,
      picaService: null,
      picacodeReleases: [],
      picacodeReleasesStatus: {},
      nextPrintSequence: null,

      taskInProgressIds: [],
      taskTimeout: null,
      /*
      { 'task id': 'task object' }
      */
      tasksRunning: {},

      isLoading: false,

      openDeleteConfirmModal: false,
      itemToDelete: null,
      itemToDeleteIndex: null,

      generatingAllLabels: false,
      downloadingAllLabels: false,
      downloadingAllPicaCodes: false,

      // iconCsv: iconCsv,
      // iconPicaCode: iconPicaCode,
      iconMore,
    }
  },

  mounted() {
    this.fields = this.getFields()
    this.initPicaServiceFromStore()
    this.loadPicacodeReleases()
  },

  destroyed() {
    clearTimeout(this.taskTimeout)
  },

  computed: {
    ...mapGetters({
      usesFaceRecognition: 'event/usesFaceRecognition',
      isEventStarted: 'event/isEventStarted',
    }),

    hasItems() {
      return this.picacodeReleases.length > 0
    },

    showPrintSeq() {
      return this.hasPerm('pica.view_print_seq')
    },


    releasesWithGeneratedLabels() {
      return Object.keys(this.picacodeReleasesStatus)
          .filter(key => this.picacodeReleasesStatus[key] === LABELS_GENERATION_COMPLETED)
          .reduce((obj, key) => {
            obj[key] = this.picacodeReleasesStatus[key];
            return obj;
          }, {});
    },

    releasesWithGeneratedLabelsCount() {
      return Object.keys(this.releasesWithGeneratedLabels).length
    },

    releasesWithLabelsGenerationInProgress() {
      return Object.keys(this.picacodeReleasesStatus)
          .filter(key => this.picacodeReleasesStatus[key] === LABELS_GENERATION_IN_PROGRESS)
          .reduce((obj, key) => {
            obj[key] = this.picacodeReleasesStatus[key];
            return obj;
          }, {});
    },

    releasesWithLabelsGenerationInProgressCount() {
      return Object.keys(this.releasesWithLabelsGenerationInProgress).length
    },

    showGenerateAllLabels() {
      if (this.usesFaceRecognition) return false
      if (this.releasesWithGeneratedLabelsCount > 0) return false
      return this.hasPerm('pica.generate_labels')
    },

    showRegenerateAllLabels() {
      if (this.usesFaceRecognition) return false
      if (this.releasesWithGeneratedLabelsCount <= 0) return false
      return this.hasPerms(['pica.generate_labels', 'dashboard.view_feature_regenerate_picacodes'])
    },

    showDownloadAllLabels() {
      if (this.usesFaceRecognition)
        return false

      return this.releasesWithGeneratedLabelsCount > 0
    },

    // nonWelcomeCodeReleasesCount() {
    //     return this.picacodeReleases.filter(i => !i.welcome_code).length
    // },
  },

  methods: {
    initPicaServiceFromStore() {
      const originalPicaService = this.$store.state.event.picaService
      this.picaService = JSON.parse(JSON.stringify(originalPicaService))
    },
    getFields() {
      const fields = {
        "id": {
          key: "id",
          label: "ID",
          sorter: true,
          filter: false,
          _style: "width:5%;"
        },
        "description": {
          key: "description",
          label: this.$gettext('Description'),
          sorter: false,
          filter: false,

        },
        "welcome_code": {
          key: "welcome_code",
          label: this.$gettext('Welcome Code'),
          sorter: false,
          filter: false,

        },
        "quantity": {
          key: "quantity",
          label: this.$gettext('Quantity'),
          sorter: false,
          filter: false,
        },
        "race_number_range": {
          key: "race_number_range",
          label: this.$gettext('From - To'),
          sorter: false,
          filter: false,

        },
        "print_seq": {
          key: 'print_seq',
          label: this.$gettext('Print sequence'),
          sorter: false,
          filter: false,

        },
        "layout": {
          key: "layout",
          label: this.$gettext('Layout'),
          sorter: false,
          filter: false,
          _style: "text-align:center;"
        },
        "code_generation_status": {
          key: "code_generation_status",
          label: this.$gettext('Actions'),
          sorter: false,
          filter: false,

        },
        "csv": {
          key: "csv",
          label: "CSV",
          sorter: false,
          filter: false,
          _style: "width:30px;text-align:center"

        },
        "zip": {
          key: "zip",
          label: "ZIP",
          sorter: false,
          filter: false,
          _style: "width:30px;text-align:center"

        },
        "actions": {
          key: "actions",
          label: "",
          sorter: false,
          filter: false,
          _style: "width:30px;text-align:center"
        }
      }

      let keys = ['id', 'description', 'quantity', 'race_number_range', 'layout', 'code_generation_status', 'csv', 'zip', 'actions']

      if (this.showPrintSeq) {
        keys = ['id', 'description', 'quantity', 'race_number_range', 'print_seq', 'layout', 'code_generation_status', 'csv', 'zip', 'actions']
      }

      if (this.usesFaceRecognition) {
        keys = ['id', 'description', 'welcome_code', 'quantity', 'code_generation_status', 'csv', 'zip', 'actions']
      }

      // if (this.hasPerm('pica.delete_picacoderelease')) {
      //     keys.unshift('id')
      // }

      let f = []
      keys.forEach(k => f.push(fields[k]))
      return f
    },

    getDirectLink(item) {
      return item.gallery_url + '&src=dl'
    },

    getQRCode(item) {
      return item.gallery_url + '&src=qr'
    },

    loadPicacodeReleases() {
      this.isLoading = true
      PicacodeReleaseService.getPicacodeReleases(this.picaService.id, {
        includeHasCheckins: true
      })
          .then((response) => {
            this.picacodeReleases = response.data.results

            this.setNextPrintSequence()
            this.buildTasksStatus()
            this.enqueueTasks()

            this.isLoading = false

            this.maybeStartMonitor()
          })
    },

    showButtonActionExportCsv(item) {
      if (item.welcome_code) return false
      const status = this.getPicacodeReleaseStatus(item)
      return [
        PICACODES_GENERATION_COMPLETED,
        LABELS_GENERATION_IN_PROGRESS,
        LABELS_GENERATION_COMPLETED
      ].includes(status)
    },

    showButtonActionGenerateLabels(item) {
      if (this.usesFaceRecognition) return false
      const status = this.getPicacodeReleaseStatus(item)
      return status === PICACODES_GENERATION_COMPLETED && this.hasPerm('pica.generate_labels')
    },

    showButtonActionRegenerateLabels(item) {
      if (this.usesFaceRecognition) return false
      const status = this.getPicacodeReleaseStatus(item)
      return status === LABELS_GENERATION_COMPLETED && this.hasPerms(['pica.generate_labels', 'dashboard.view_feature_regenerate_picacodes'])
    },

    showButtonActionDownloadLabels(item) {
      if (this.usesFaceRecognition) return false
      const status = this.getPicacodeReleaseStatus(item)
      return status === LABELS_GENERATION_COMPLETED
    },


    appendPicacodeRelease(picacodeRelease) {
      this.picacodeReleases.push(picacodeRelease)
      this.setNextPrintSequence()
      this.buildTasksStatus(picacodeRelease)
      this.enqueueTasks(picacodeRelease)
      this.maybeStartMonitor()
    },

    setNextPrintSequence() {
      let nextPrintSequence = 1
      this.picacodeReleases.forEach((picacodeRelease) => {
        let ps = +picacodeRelease.print_seq
        if (ps >= nextPrintSequence) {
          nextPrintSequence = ps + picacodeRelease.quantity
        }
      })
      this.nextPrintSequence = nextPrintSequence
    },

    buildTasksStatus(picacodeRelease) {
      let picacodeReleases

      if (picacodeRelease) {
        picacodeReleases = [picacodeRelease]
      } else {
        // build all
        this.picacodeReleasesStatus = {}
        picacodeReleases = this.picacodeReleases
      }

      picacodeReleases.forEach((picacodeRelease) => {
        let status = null

        if (picacodeRelease.task_id_labels_generation !== null)
          status = LABELS_GENERATION_IN_PROGRESS

        else if (picacodeRelease.labels_file !== null)
          status = LABELS_GENERATION_COMPLETED

        else if (picacodeRelease.is_picacodes_generation_completed === true)
          status = PICACODES_GENERATION_COMPLETED

        else if (picacodeRelease.is_picacodes_generation_completed === false && picacodeRelease.task_id_picacodes_generation !== null)
          status = PICACODES_GENERATION_IN_PROGRESS

        this.setPicacodeReleaseStatus(picacodeRelease.id, status)
      })
    },

    enqueueTasks(picacodeRelease) {
      // popolo taskInProgressIds con i task attivi

      const picacodeReleases = picacodeRelease ? [picacodeRelease] : this.picacodeReleases

      picacodeReleases.forEach((picacodeRelease) => {
        const taskId = this.getTaskId(picacodeRelease)
        if (taskId) {
          this.taskInProgressIds.push(taskId)
        }
      })
    },

    getPrintSequenceRange(picacodeRelease) {
      return `${picacodeRelease.print_seq} - ${picacodeRelease.print_seq + picacodeRelease.quantity - 1}`
    },

    getPicacodeReleaseStatus(picacodeRelease) {
      return this.picacodeReleasesStatus[picacodeRelease.id]
    },

    setPicacodeReleaseStatus(picacodeReleaseId, status) {
      this.$set(this.picacodeReleasesStatus, picacodeReleaseId, status)
    },

    getTaskId(picacodeRelease) {
      let taskId = null
      const status = this.getPicacodeReleaseStatus(picacodeRelease)

      if (status === PICACODES_GENERATION_IN_PROGRESS)
        taskId = picacodeRelease.task_id_picacodes_generation
      else if (status === LABELS_GENERATION_IN_PROGRESS)
        taskId = picacodeRelease.task_id_labels_generation

      return taskId
    },

    picacodeReleaseFromTaskId(taskId) {
      return this.picacodeReleases.find((picacodeRelease) => this.getTaskId(picacodeRelease) === taskId)
    },

    getTasksStatus() {
      PicacodeReleaseService.listTaskStatus(this.taskInProgressIds)
          .then((response) => {
            response.data.forEach((task) => {
              if (task.status === "PROGRESS") {
                this.$set(this.tasksRunning, task.id, task)
              } else if (task.status === "SUCCESS") {
                // rimuovo il task id da quelli osservati
                this.taskInProgressIds = this.taskInProgressIds.filter((item) => item !== task.id)

                const picacodeRelease = this.picacodeReleaseFromTaskId(task.id)

                PicacodeReleaseService.retrievePicacodeRelease(picacodeRelease.id, {includeHasCheckins: true})
                    .then((response) => {
                      const picacodeReleaseUpdated = response.data
                      // rinfresco l'istanza locale del picacode release
                      const index = this.picacodeReleases.findIndex(ps => ps.id === picacodeRelease.id)
                      this.$set(this.picacodeReleases, index, picacodeReleaseUpdated)
                      this.buildTasksStatus(picacodeReleaseUpdated)
                    })

                // let newStatus = null
                // if (task.id === picacodeRelease.task_id_picacodes_generation)
                //     newStatus = PICACODES_GENERATION_COMPLETED
                // else if (task.id === picacodeRelease.task_id_labels_generation)
                //     newStatus = LABELS_GENERATION_COMPLETED
                // this.setPicacodeReleaseStatus(picacodeRelease.id, newStatus)
              }
            })

            this.maybeStartMonitor()
            this.maybeEndMonitor()
          })
    },

    maybeStartMonitor() {
      if (this.taskInProgressIds.length > 0)
        this.taskTimeout = this.monitorTasksStart()
    },

    maybeEndMonitor() {
      if (this.taskInProgressIds.length === 0)
        clearTimeout(this.taskTimeout)
    },

    monitorTasksStart() {
      // console.log("monitorTasksStart")
      return setTimeout(this.getTasksStatus, 1000)
    },

    taskData(picacodeRelease) {
      const taskId = this.getTaskId(picacodeRelease)
      if (taskId && taskId in this.tasksRunning)
        return this.tasksRunning[taskId]
      return {}
    },

    taskCurrent(picacodeRelease) {
      const taskData = this.taskData(picacodeRelease)
      if ("info" in taskData)
        return taskData["info"]["current"]
      return 0
    },

    taskTotal(picacodeRelease) {
      const taskData = this.taskData(picacodeRelease)
      if ("info" in taskData)
        return taskData["info"]["total"]
      // se restituisco 0, show-percentage calcola 0/0 = NaN
      return 1
    },

    toggleDetails(item, index) {
      this.$set(this.picacodeReleases[index], "_toggled", !item._toggled)
      this.collapseDuration = 300
      this.$nextTick(() => {
        this.collapseDuration = 0
      })
    },

    exportCsv(picacodeRelease) {
      PicacodeReleaseService.exportCsv(picacodeRelease.id)
    },

    startLabelsGeneration(evt) {
      this.generatingAllLabels = true
      PicacodeReleaseService.generateLabels(evt.ids, evt.layoutId, evt.format)
          .then(() => {
            this.loadPicacodeReleases()
          })
          .finally(() => {
            this.generatingAllLabels = false
          })
      // .then(() => this.monitorTasksStart())
    },

    highlightRows(data) {
      const picacodeReleaseIds = data.map(o => o.picacode_release_id)
      this.picacodeReleases.forEach((item, idx) => {
        if (picacodeReleaseIds.includes(item.id)) {
          item["_classes"] = "bg-warning"
          this.$set(this.picacodeReleases, idx, item)
        }
      })
    },

    resetHighlightRows() {
      this.picacodeReleases.forEach((item, idx) => {
        delete item["_classes"]
        this.$set(this.picacodeReleases, idx, item)
      })
    },

    showDeleteConfirmModal(item, index) {
      this.itemToDelete = item
      this.itemToDeleteIndex = index
      this.openDeleteConfirmModal = true
    },

    hideDeleteConfirmModal() {
      this.itemToDelete = null
      this.itemToDeleteIndex = null
      this.openDeleteConfirmModal = false
    },

    deleteRow(index) {
      this.$delete(this.picacodeReleases, index)
      this.buildTasksStatus()
    },

    deletePicacodeRelease(picacodeRelease, index) {
      PicacodeReleaseService.deletePicacodeRelease(picacodeRelease.id)
          .then(() => {
            this.deleteRow(index)
            this.setNextPrintSequence()
            // TODO: rimuovere eventuali task id dalla coda
            this.hideDeleteConfirmModal()
          })
    },

    canDeletePicacodeRelease(item) {
      if (item === null) return false
      return item.has_checkins === false && this.isEventStarted === false && this.hasPerm('pica.delete_picacoderelease')
    },

    copyURL(url, description) {
      navigator.clipboard.writeText(url);
      this.notifyMessage({text: this.$gettext(description + ' copied!'), messageType: 'success'})
    },

    async downloadAllLabels() {
      this.downloadingAllLabels = true
      try {
        await UtilityService.downloadAllLabels(this.picaService.id)
      } finally {
        this.downloadingAllLabels = false
      }
    },
    async downloadAllPicaCodes() {
      this.downloadingAllPicaCodes = true
      try {
        await  PicacodeReleaseService.exportAllPicacodes(this.picaService.id)
      } finally {
        this.downloadingAllPicaCodes = false
      }
    }
  }
}
</script>
