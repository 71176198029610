import * as url from "url"
import client_core from "@/services/clients/client_core";

url.URLSearchParams = URLSearchParams

const PicacodeReleaseService = {

    getPicacodeReleases(picaServiceId, {includeHasCheckins} = {}) {
        let params = {'pica_service': picaServiceId}
        if (includeHasCheckins === true)
            params['include_has_checkins'] = 'true'

        return client_core.get('/dashboard/v1/picacode-releases/', { params })
    },

    retrievePicacodeRelease(picaCodeReleaseId, {includeHasCheckins} = {}) {
        let params = {}
        if (includeHasCheckins === true)
            params['include_has_checkins'] = 'true'

        return client_core.get(`/dashboard/v1/picacode-releases/${picaCodeReleaseId}/`, { params })
    },

    createPicacodeRelease(params) {
        return client_core.post('/dashboard/v1/picacode-releases/', params)
    },

    updatePicacodeRelease(picacodeReleaseId, params) {
        return client_core.patch(`/dashboard/v1/picacode-releases/${picacodeReleaseId}/`, params)
    },

    deletePicacodeRelease(picacodeReleaseId) {
        return client_core.delete(`/dashboard/v1/picacode-releases/${picacodeReleaseId}/`)
    },

    exportCsv(picacodeReleaseId) {
        return client_core({
            method: 'get',
            url: `/dashboard/v1/picacode-releases/${picacodeReleaseId}/export-csv/`,
            responseType: 'blob',
        }).then(function(response) {
            let blob = new Blob([response.data], { type: 'text/csv' })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `export-${picacodeReleaseId}.csv`
            document.body.appendChild(link)
            link.click()
        })
    },

    exportAllPicacodes(picaServiceId) {
        return client_core({
            method: 'get',
            params: {pica_service: picaServiceId},
            url: `/dashboard/v1/picacode-releases/export-csv/`,
            responseType: 'blob',
        }).then(function(response) {
            let blob = new Blob([response.data], { type: 'text/csv' })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = `export-all-${picaServiceId}.csv`
            document.body.appendChild(link)
            link.click()
        })
    },

    generateLabels(picacodeReleaseIds, labelsLayoutId, format) {
        let axiosArray = []

        // console.log(picacodeReleaseIds)

        picacodeReleaseIds.forEach(picacodeReleaseId => {
            axiosArray.push(
                client_core.post(`/dashboard/v1/picacode-releases/${picacodeReleaseId}/generate-labels/`, {
                    labels_layout: labelsLayoutId,
                    file_format: format.toLowerCase()
                })
            )
        })

        return Promise.all(axiosArray)
    },

    listTaskStatus(taskIds) {
        const params = {'task_id': taskIds}
        return client_core.get(`/dashboard/v1/tasks/`, {params})
    },
}

export default PicacodeReleaseService